import ReactGA from 'react-ga';
import getConfig from 'next/config';

import localStorageService from '../storage-service/local-storage.service';
import { HOMII_KEYS } from '../../../helpers/constants';

const HOMII_STORAGE_KEY = HOMII_KEYS.LOCAL_STORAGE_KEY;
const SUCCESSFUL_BOOKING_EVENT = 'successful_booking_event';
const DATA_BOOKING_EVENT = 'data_booking_event';

const initGoogleAnalytics = () => {
    const { publicRuntimeConfig } = getConfig();
    ReactGA.initialize(publicRuntimeConfig.HOMII_GA_TRACKING_ID);
};

const logPageView = () => {
    const pathName = window.location.pathname;
    ReactGA.plugin.require('ecommerce');
    ReactGA.set({ page: pathName });

    if (process.env.NODE_ENV === 'production') {
        logEcommerceTransaction();
    }

    ReactGA.pageview(pathName);
};

const logEcommerceTransaction = () => {
    const storage = localStorageService.getItem(HOMII_STORAGE_KEY);
    let preBookingReducer = null;
    if (storage) {
        preBookingReducer = storage.preBookingReducer;
    }

    if (localStorageService.getItem(SUCCESSFUL_BOOKING_EVENT)) {
        const bookingData = localStorageService.getItem(DATA_BOOKING_EVENT);

        ReactGA.plugin.execute('ecommerce', 'addTransaction', {
            id: preBookingReducer.preBookingId,
            revenue: preBookingReducer.preBookingQuote.amount,
            currency: 'ZAR',
        });
        ReactGA.plugin.execute('ecommerce', 'addItem', {
            id: preBookingReducer.preBookingId,
            name: `${bookingData.description} - ${bookingData.buildingName}`,
            sku: bookingData.id,
            category: bookingData.category,
            price: preBookingReducer.preBookingQuote.amount,
            quantity: 1,
            currency: 'ZAR',
        });
        ReactGA.plugin.execute('ecommerce', 'send');
        ReactGA.plugin.execute('ecommerce', 'clear');

        localStorageService.setItem(SUCCESSFUL_BOOKING_EVENT, false);
    }
};

export default {
    initGoogleAnalytics,
    logPageView,
};
